import React from 'react';
import { Link } from 'react-router-dom';
import { css } from 'glamor';
import * as styles from './articlepage.style';
import axios from 'axios';
import * as translate from '../../assets/translations/translations';
import Section from '../../components/section/section';
import rightArrow from '../../assets/icons/arrow-link-next_white.svg';
import downloadIcon from '../../assets/icons/download_white.svg';
import * as languages from '../../configs/languages';
import catalogTranslations from '../../assets/translations/subcategories.json';

export default class ArticlePageView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            article: props.article,
        };
        window.articleLoaded(props.article);
    }

    formatDate(date) {
        var date = new Date(date);
        return date.toLocaleDateString("da-DK").replace(/-/g, "/");
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        let language = this.props.article.language;
        let selectedLanguageLocale = languages.Languages.filter((edition) => edition.edition == language)

        let currentdate = new Date();
        currentdate = currentdate.getFullYear() + '-' + (currentdate.getMonth() + 1) + '-' + currentdate.getDate();
        let requestBody = {
            body: {
                "app_id": "research",
                "date_time": currentdate,
                "event_name": "research:click_event",
                "user_id": "Suraj",
                "event_data": {
                    "article_id": this.state.article[0].articleid
                }
            }
        }

        axios({
            url: window.env.ClickUrl,
            data: requestBody,
            method: 'post',
            headers: {
                'X-IBM-Client-Id': window.env.IBMClientId,
                'X-IBM-Client-Secret': window.env.IBMClientSecret,
                'Accept-Language': selectedLanguageLocale.langaugeLocale
            }
        })
            .then(() => {
            });

    }

    getCatalogtranslations(text) {
        let edition = localStorage.getItem('language') || 'EN';
        if (edition == 'EN' || edition == 'ALL') {
            return text;
        }
        else {
            if (catalogTranslations[text]) {
                let translationElement = catalogTranslations[text];
                let translatedText = translationElement[edition];
                return translatedText;
            }
            else {
                return text;
            }
        }
    }

    renderSubcategories(categoryInfo) {
        const ciCount = categoryInfo?.length;
        return categoryInfo?.map((ci, i) => (
            <span key={i}>
                <Link aria-label={this.getCatalogtranslations(ci.subcategory)} alt={this.getCatalogtranslations(ci.subcategory)} to={this.props.commr ? `/articlelist/${ci.subcategory}/commr` : `/articlelist/${ci.subcategory}`}
                    {...css(styles.summaryAnchor)}>{this.getCatalogtranslations(ci.subcategory)}{ciCount !== i + 1 && ", "}</Link>
            </span>
        ));
    }

    render() {
        return (
            <div className="container-fluid" {...css(styles.articleContent)}>
                <div className="row">
                    <div className="col-md-12 col-lg-12 col-sm-12 col-12" {...css(styles.padding0)}>
                        <div role='region' aria-label="Category">
                            {this.renderSubcategories(this.state.article[0].categoryInfo)}
                        </div>
                        {this.state.article[0].keywords == null || this.state.article[0].keywords == undefined || this.state.article[0].keywords == '' ?
                            null
                            : <div role='region' aria-label="Tags"><Link aria-label={this.state.article[0].keywords} alt={this.state.article[0].keywords} to={`/keywords/${this.state.article[0].keywords}`}> <span {...css(styles.focusTag)}>{this.state.article[0].keywords}</span> </Link></div>}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 col-lg-12 col-sm-12 col-12" {...css(styles.padding0)}>
                        {this.state.article[0].published_url != null && this.state.article[0].published_url != undefined && this.state.article[0].published_url != '' ?
                            <div className="row">
                                <div {...css(styles.siteUrl)} className="col-md-12 col-lg-12 col-sm-12 col-12" style={{ 'text-align': 'center' }}>
                                    {this.state.article[0].published_url.toLowerCase().indexOf('.pdf') !== -1 ?
                                        <div role='region' aria-label="Heading"><Link aria-label={this.state.article[0].title} alt={this.state.article[0].title} to={{
                                            pathname: `/articlepreview/${this.state.article[0].articleid}/${this.state.article[0].language}${this.props.commr ? '/commr' : ''}`
                                        }} {...css(styles.H2)} >{this.state.article[0].title}
                                        </Link></div> :
                                        <a aria-label={this.state.article[0].title} alt={this.state.article[0].title} {...css(styles.H2)} href={this.state.article[0].published_url} download>{this.state.article[0].title}</a>
                                    }
                                </div>
                                <div {...css(styles.tabletUrl)} className="col-md-12 col-lg-12 col-sm-12 col-12" style={{ 'text-align': 'center' }} role='region' aria-label="Published Date">
                                    <a aria-label={this.state.article[0].title} alt={this.state.article[0].title} {...css(styles.H2)} href={this.state.article[0].published_url} download>{this.state.article[0].title}</a>
                                </div>
                            </div> : <h2 {...css(styles.H2WithoutUnderline)}>{this.state.article[0].title}</h2>}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 col-lg-12 col-sm-12 col-12" {...css(styles.padding0)} role='region' aria-label="Published Date">
                        <p {...css(styles.pubDate)} >{this.formatDate(this.state.article[0].published_date)}
                        </p>
                    </div>
                </div>
                <div role='region' aria-label="Article Content">
                    {(this.state.article[0].thumbnailMobile != null && this.state.article[0].thumbnailMobile != undefined) ||
                        (this.state.article[0].mobile_text != null && this.state.article[0].mobile_text != undefined) ?

                        <Section image={this.state.article[0].thumbnailMobile}
                            summary={this.state.article[0].mobile_text} />
                        : null}
                    {(this.state.article[0].thumbnailMobile1 != null && this.state.article[0].thumbnailMobile1 != undefined) ||
                        (this.state.article[0].mobile_text1 != null && this.state.article[0].mobile_text1 != undefined) ?

                        <Section image={this.state.article.thumbnailMobile1}
                            summary={this.state.article.mobile_text1} />
                        : null}
                    {(this.state.article[0].thumbnailMobile2 != null && this.state.article[0].thumbnailMobile2 != undefined) ||
                        (this.state.article[0].mobile_text2 != null && this.state.article[0].mobile_text2 != undefined) ?

                        <Section image={this.state.article[0].thumbnailMobile2}
                            summary={this.state.article[0].mobile_text2} />
                        : null}
                    {(this.state.article[0].thumbnailMobile3 != null && this.state.article[0].thumbnailMobile3 != undefined) ||
                        (this.state.article[0].mobile_text3 != null && this.state.article[0].mobile_text3 != undefined) ?

                        <Section image={this.state.article[0].thumbnailMobile3}
                            summary={this.state.article[0].mobile_text3} />
                        : null}
                    {(this.state.article[0].thumbnailMobile4 != null && this.state.article[0].thumbnailMobile4 != undefined) ||
                        (this.state.article[0].mobile_text4 != null && this.state.article[0].mobile_text4 != undefined) ?

                        <Section image={this.state.article[0].thumbnailMobile4}
                            summary={this.state.article[0].mobile_text4} />
                        : null}
                </div>
                {this.state.article[0].published_url != null && this.state.article[0].published_url != undefined && this.state.article[0].published_url != '' ?
                    <div className="row" role='region' aria-label="Others">
                        <div {...css(styles.siteUrl)} className="col-md-12 col-lg-12 col-sm-12 col-12" style={{ 'text-align': 'center' }}>
                            {this.state.article[0].published_url.toLowerCase().indexOf('.pdf') !== -1 ?
                                <Link aria-label={translate.strings.TR197} alt={translate.strings.TR197} to={{
                                    pathname: `/articlepreview/${this.state.article[0].articleid}/${this.state.article[0].language}${this.props.commr ? '/commr' : ''}`
                                }} {...css(styles.articleButton)} >{translate.strings.TR197}
                                    <img {...css(styles.rightArrow)} src={rightArrow} alt={"rightArrow"} />
                                </Link> :
                                <a aria-label="Download Article" alt="Download Article" {...css(styles.articleButton)} href={this.state.article[0].published_url} download>Download Article
                                    <img {...css(styles.downloadIcon)} src={downloadIcon} alt={"Download Article"} />
                                </a>
                            }
                        </div>
                        <div {...css(styles.tabletUrl)} className="col-md-12 col-lg-12 col-sm-12 col-12" style={{ 'text-align': 'center' }}>
                            <a aria-label={translate.strings.TR197} alt={translate.strings.TR197} {...css(styles.articleButton)} href={this.state.article[0].published_url} download>{translate.strings.TR197}
                                <img {...css(styles.rightArrow)} src={rightArrow} alt={translate.strings.TR197} />
                            </a>
                        </div>
                    </div> : null}
            </div>
        )
    }
}