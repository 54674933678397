import * as translate from '../assets/translations/translations';
let language = localStorage.getItem('language') || 'EN';
translate.strings.setLanguage(language);

export const navInfo = [
    {
        "text": translate.strings.TR54,
        "url": "Macroeconomics",
        "nonEeaAccessible": true,
        "submenu": [
            {
                "title": translate.strings.TR90,
                "items": [{
                    "text": translate.strings.TR91,
                    "url": "Denmark"
                },
                {
                    "text": translate.strings.TR92,
                    "url": "Sweden"
                },
                {
                    "text": translate.strings.TR93,
                    "url": "Norway"
                },
                {
                    "text": translate.strings.TR94,
                    "url": "Finland"
                },
                {
                    "text": translate.strings.TR95,
                    "url": "Nordic Outlook"
                }]
            },
            {
                "title": translate.strings.TR121,
                "items": [{
                    "text": translate.strings.TR122,
                    "url": "RtM Denmark"
                },
                {
                    "text": translate.strings.TR124,
                    "url": "RtM Sweden"
                },
                {
                    "text": translate.strings.TR123,
                    "url": "RtM Norway"
                },
                {
                    "text": "RtM EUR",
                    "url": "RtM EUR"
                },
                {
                    "text": "RtM USD",
                    "url": "RtM USD"
                }]
            },
            {
                "title": translate.strings.TR63,
                "items": [{
                    "text": translate.strings.TR97,
                    "url": "Euro area"
                },
                {
                    "text": translate.strings.TR98,
                    "url": "US"
                },
                {
                    "text": translate.strings.TR99,
                    "url": "UK"
                },
                {
                    "text": translate.strings.TR100,
                    "url": "China"
                },
                {
                    "text": translate.strings.TR101,
                    "url": "Japan"
                },
                {
                    "text": translate.strings.TR102,
                    "url": "Emerging Markets"
                },
                {
                    "text": translate.strings.TR103,
                    "url": "The Big Picture"
                }]
            },
            {
                "title": translate.strings.TR64,
                "items": [{
                    "text": translate.strings.TR105,
                    "url": "Danske Daily"
                },
                {
                    "text": translate.strings.TR106,
                    "url": "Weekly Focus"
                },
                {
                    "text": translate.strings.TR107,
                    "url": "Market Movers"
                }]
            },
            {
                "title": translate.strings.TR69,
                "items": [{
                    "text": translate.strings.TR109,
                    "url": "Strategy"
                }
                ]
            }

        ]
    },
    {
        "text": translate.strings.TR55,
        "url": "Fixed Income",
        "nonEeaAccessible": true,
        "submenu": [
            {
                "title": "Products",
                "items": [{
                    "text": "EUR Fixed Income",
                    "url": "EUR Fixed Income"
                },
                {
                    "text": "Danish Fixed Income",
                    "url": "Danish Fixed Income"
                },
                {
                    "text": "Swedish Fixed Income",
                    "url": "Swedish Fixed Income"
                },
                {
                    "text": "Norwegian Fixed Income",
                    "url": "Norwegian Fixed Income"
                },
                {
                    "text": translate.strings.TR115,
                    "url": "Yield Outlook"
                }]
            },
            {
                "title": "Short market comments",
                "items": [{
                    "text": "EUR Fixed Income",
                    "url": "EUR Fixed Income Comments"
                },
                {
                    "text": "Danish Fixed Income",
                    "url": "DKK Fixed Income Comments"
                },
                {
                    "text": "Swedish Fixed Income",
                    "url": "SEK Fixed Income Comments"
                },
                {
                    "text": "Norwegian Fixed Income",
                    "url": "NOK Fixed Income Comments"
                }]
            },
            {
                "title": "Handbooks & Key figures",
                "items": [{
                    "text": translate.strings.TR129,
                    "url": "Danish Covered Bond Handbook"
                },
                {
                    "text": translate.strings.TR130,
                    "url": "Nordic Covered Bond Handbook"
                },
                {
                    "text": translate.strings.TR117,
                    "url": "Danish Mortgage Bonds key figures"
                },
                {
                    "text": "Key Figures Description",
                    "url": "Key Figures Description"
                }]
            },
            {
                "title": translate.strings.TR125,
                "items": [{
                    "text": translate.strings.TR126,
                    "url": "Market Guide"
                },
                {
                    "text": translate.strings.TR127,
                    "url": "Rates Hedging"
                }]
            }
        ]
    },
    {
        "text": translate.strings.TR56,
        "url": "Foreign Exchange",
        "nonEeaAccessible": true,
        "submenu": [
            {
                "title": translate.strings.TR64,
                "items": [                
                {
                    "text": "FX Briefs",
                    "url": "FX Briefs"
                },
                // {
                //     "text": "FX Essentials",
                //     "url": "FX Essentials"
                // },
                {
                    "text": translate.strings.TR134,
                    "url": "IMM Positioning Update"
                },
                {
                    "text": translate.strings.TR133,
                    "url": "FX Forecast Update"
                },
                {
                    "text": translate.strings.TR102,
                    "url": "Emerging Markets"
                },
                {
                    "text": "FX Thermometer",
                    "url": "FX Thermometer"
                }]
            },
            {
                "title": "Ad hoc",
                "items": [{
                    "text": translate.strings.TR139,
                    "url": "FX Strategy"
                },
                {
                    "text": translate.strings.TR141,
                    "url": "FX Trading Portfolio"
                }]
            },
            {
                "title": translate.strings.TR121,
                "items": [{
                    "text": translate.strings.TR122,
                    "url": "RtM Denmark"
                },
                {
                    "text": translate.strings.TR124,
                    "url": "RtM Sweden"
                },
                {
                    "text": translate.strings.TR123,
                    "url": "RtM Norway"
                },
                {
                    "text": "RtM EUR",
                    "url": "RtM EUR"
                },
                {
                    "text": "RtM USD",
                    "url": "RtM USD"
                }]
            },
            {
                "title": translate.strings.TR125,
                "items": [{
                    "text": translate.strings.TR126,
                    "url": "Market Guide"
                },
                {
                    "text": "EM Currency Guide",
                    "url": "EM Currency Guide"
                }]
            }

        ]
    },
    {
        "text": translate.strings.TR70,
        "url": "Credit",
        "nonEeaAccessible": false,
        "submenu": [
            {
                "title": translate.strings.TR64,
                "items": [{
                    "text": translate.strings.TR151,
                    "url": "Handbooks"
                },
                {
                    "text": translate.strings.TR152,
                    "url": "Credit Weekly"
                },
                {
                    "text": translate.strings.TR224,
                    "url": "Other Periodicals"
                }]
            },
            {
                "title": translate.strings.TR71,
                "items": [
                {
                    "text": translate.strings.TR155,
                    "url": "Consumer goods"
                },
                {
                    "text": translate.strings.TR156,
                    "url": "Energy"
                },
                {
                    "text": translate.strings.TR158,
                    "url": "Healthcare"
                },
                {
                    "text": translate.strings.TR157,
                    "url": "Industrials"
                },
                {
                    "text": translate.strings.TR159,
                    "url": "Materials"
                },
                {
                    "text": translate.strings.TR160,
                    "url": "Real estate"
                },
                {
                    "text": translate.strings.TR161,
                    "url": "Services"
                },
                {
                    "text": translate.strings.TR162,
                    "url": "Shipping & Transportation"
                },
                {
                    "text": translate.strings.TR163,
                    "url": "TMT"
                },
                {
                    "text": translate.strings.TR165,
                    "url": "Utilities"
                }]
            },
            {
                "title": translate.strings.TR72,
                "items": [{
                    "text": translate.strings.TR168,
                    "url": "Financials"
                }]
            },
            {
                "title": translate.strings.TR170,
                "items": [{
                    "text": translate.strings.TR169,
                    "url": "Covered Bond Update"
                }
                ]
            },
            {
                "title": translate.strings.TR257,
                "items": [{
                    "text": "Corporate Bond Lists",
                    "url": "Corporate Bond Lists"
                },
                {
                    "text": "Non-Nordic Credit Research",
                    "url": "Non-Nordic Credit Research"
                }
                ]
            },
            {
                "title": translate.strings.TR225,
                "items": [{
                    "text": "Other Credit research",
                    "url": "Other Credit research"
                },
                {
                    "text": "Credit Comments",
                    "url": "Credit Comments"
                },
                {
                    "text": translate.strings.TR259,
                    "url": "Other Comments"
                }]
            }
        ]
    },
    {
        "text": translate.strings.TR74,
        "url": "Forecast",
        "nonEeaAccessible": true,
        "submenu": [
            {
                "title": translate.strings.TR172,
                "items": [{
                    "text": translate.strings.TR173,
                    "url": "Macroeconomic forecast"
                },
                {
                    "text": translate.strings.TR174,
                    "url": "Economic data calendar"
                },
                {
                    "text": translate.strings.TR227,
                    "url": "Inflation forecast"
                }
                ]
            },
            {
                "title": translate.strings.TR175,
                "items": [
               
                {
                    "text": translate.strings.TR175,
                    "url": "Financial Forecasts"
                }
                ]
            },
            {
                "title": translate.strings.TR180,
                "items": [{
                    "text": translate.strings.TR181,
                    "url": "EUR"
                },
                {
                    "text": translate.strings.TR182,
                    "url": "USD"
                },
                {
                    "text": translate.strings.TR183,
                    "url": "DKK"
                },
                {
                    "text": translate.strings.TR184,
                    "url": "SEK"
                },
                {
                    "text": translate.strings.TR185,
                    "url": "NOK"
                },
                {
                    "text": translate.strings.TR240,
                    "url": "GBP"
                }]
            },
            {
                "title": translate.strings.TR238,
                "items": [{
                    "text": translate.strings.TR115,
                    "url": "Yield Outlook"
                },
                {
                    "text": translate.strings.TR133,
                    "url": "FX Forecast Update"
                }]
            }
        ]
    },
    {
        "text": translate.strings.TR260,
        "url": "War in Ukraine",
        "nonEeaAccessible": true,
        "submenu": [
            {
                "title": translate.strings.TR260,
                "items": [{
                    "text": translate.strings.TR261,
                    "url": "Updates"
                }
                ]
            }
        ]
    },
    {
        "text": translate.strings.TR256,
        "url": "ESG Climate",
        "nonEeaAccessible": true,
        "submenu": [
            {
                "title": translate.strings.TR54,
                "items": [{
                    "text": translate.strings.TR253,
                    "url": "All Macroeconomics ESG Climate"
                }
                ]
            },
            {
                "title": translate.strings.TR55,
                "items": [{
                    "text": translate.strings.TR254,
                    "url": "All Fixed Income ESG Climate"
                }
                ]
            },
            {
                "title": translate.strings.TR70,
                "items": [{
                    "text": translate.strings.TR255,
                    "url": "All Credit ESG Climate"
                }
                ]
            },
            {
                "title": "Sustainable Finance",
                "items": [{
                    "text": "Weekly Newsletter",
                    "url": "Weekly Newsletter"
                }
                ]
            }
        ]
    },
    {
        "text": translate.strings.TR231,
        "url": "Podcasts",
        "nonEeaAccessible": true
    },
    {
        "text": translate.strings.TR222,
        "url": "Presentations",
        "nonEeaAccessible": true,
        "submenu": [
            {
                "title": translate.strings.TR222,
                "items": [{
                    "text": translate.strings.TR223,
                    "url": "All Presentations"
                }
                ]
            }
        ]
    },
    {
        "text": translate.strings.TR82,
        "url": "Equities",
        "nonEeaAccessible": false,
        "submenu": [
            {
                "title": translate.strings.TR188,
                "items": [
                    {
                        "text": translate.strings.TR188,
                        "url": translate.strings.TR188
                    }
                ]
            },
            {
                "title": translate.strings.TR64,
                "items": [{
                    "text": "Aktienyt",
                    "url": "Aktienyt"
                }]
            },
            {
                "title": "Equity Research",
                "items": [{
                    "text": translate.strings.TR190,
                    "url": "Danske Equities Log In"
                    }
                ]
            }
        ]
    },
    {
        "text": translate.strings.TR83,
        "url": "About",
        "nonEeaAccessible": true,
        "submenu": [
            {
                "title": "",
                "items": [{
                    "text": translate.strings.TR84,
                    "url": "about"
                },
                {
                    "text": translate.strings.TR85,
                    "url": "faq"
                },
                {
                    "text": translate.strings.TR13,
                    "url": "contactus"
                }
                ]
            }
        ]
    }
];

export const companyList = [
    {
        text: "Latest Commissioned Research",
        url: "Latest Commissioned Research"
    },    
    {
        text: "Bioretec",
        url: "Bioretec"
    },
    {
        text: "Fiskars",
        url: "Fiskars"
    },
    {
        text: translate.strings.TR246,
        url: "Glaston"
    },
    {
        text: "Kreate Group",
        url: "Kreate Group"
    },
    {
        text: "Lamor",
        url: "Lamor"
    },
    {
        text: "Oma Savings Bank",
        url: "Oma Savings Bank"
    },
    {
        text: "Pharma Equity Group",
        url: "Pharma Equity Group"
    },
    {
        text: "Pihlajalinna",
        url: "Pihlajalinna"
    },
    {
        text: "RTX",
        url: "RTX"
    },
    {
        text: "Sparekassen Sjælland-Fyn",
        url: "Sparekassen Sjælland-Fyn"
    },
    {
        text: "Spinnova",
        url: "Spinnova"
    },
    {
        text: translate.strings.TR248,
        url: "Talenom"
    },
    {
        text: "Verkkokauppa.com",
        url: "Verkkokauppa.com"
    }       
    
];

export const commrNavInfo = [
    {
        text: translate.strings.TR252
    },
    {
        text: translate.strings.TR250,
        nonEeaAccessible: false,
        submenu: [
            {
                title: "",
                items:  companyList
            }
        ]
    },
    {
        text: translate.strings.TR83
    },
    {
        text: translate.strings.TR251
    }
];
