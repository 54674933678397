import React from 'react';
import { Link } from 'react-router-dom';
import { css, style } from 'glamor';
import * as styles from './subscription.style';
import HeaderSection from '../common/header/header';
import HeaderTabletSection from '../common/header/header.tablet';
import FooterSection from '../common/footer/footer';
import NavBar from '../../components/navbar/navbar';
import axios from 'axios';
import * as navigation from '../../configs/navigation';
import * as translate from '../../assets/translations/translations';
import { connect } from 'react-redux';
import Collapsible from 'react-collapsible';
import expandIcon from '../../assets/icons/expand-small_darkblue.svg';
import * as categories from '../../configs/subscription';
import greenTick from '../../assets/icons/tick_green.svg';
import Config from '../../app.config';
import collapseIcon from '../../assets/icons/collapse-small_darkblue.svg';
import catalogTranslations from '../../assets/translations/subcategories.json';
import Search from '../search/search';

class SubscriptionPage extends React.Component {

    constructor(props) {
        super();

        this.state = {
            showCategory: true,
            showSubcategory: false,
            showForm: false,
            showSucess: false,
            showContactMessage: false,
            category: '',
            subCategoriesList: { 'Macroeconomics': [], 'Foreign Exchange': [], 'Commodities': [], 'Fixed Income': [] },
            selectedList: [],
            subscriptionDetail: [],
            name: '',
            email: '',
            company: '',
            manager: '',
            subscribeClicked: false,
            validName: false,
            validEmail: false,
            validCompany: false,
            acceptedAgreement: false,
            professional: false,
            agreementConditionErrors: false,
            translatedCategory: '',
            showSearch: false,
            countryList: []
        };
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleCoompanyFieldChange = this.handleCoompanyFieldChange.bind(this);
        this.handleManagerFieldChange = this.handleManagerFieldChange.bind(this);
        this.submitSubscriptionForm = this.submitSubscriptionForm.bind(this);
        this.handleSubscriptionCheckboxes = this.handleSubscriptionCheckboxes.bind(this);
        this.showSearchComponent = this.showSearchComponent.bind(this);
    }

    showSearchComponent = (value) => {
        this.setState({ showSearch: value });
        setTimeout(function () { if (document.getElementById("searchText") != null) { document.getElementById("searchText").focus(); } }, 300);
    }

    componentDidMount() {
        document.title = 'Subscription - ' + translate.strings.TR14
        window.scrollTo(0, 0);
        // axios.get(window.env.countryUrl).then(response => {
        //     if (response?.data?.country?.length > 0) {
        //         this.setState({
        //             countryList: response?.data?.country
        //         })
        //     }
        // }).catch(err => {
        //     this.setState({ countryList: [] });
        // });
    }

    populateSubcategories(categoryName) {
        window.scrollTo(0, 0);
        let categoryType = categoryName;
        this.setState({ category: categoryType })
        this.setState({ translatedCategory: this.getCatalogtranslations(categoryType) })

        if (this.state.subCategoriesList[categoryName].length > 0) {
            let subCategories = this.state.subCategoriesList[categoryName];

        }
        else {
            let categoryData = categories.subscriptions.filter(subscription => subscription.category == categoryType)
            let subCategories = categoryData[0].subcategory;
            subCategories.forEach(subcategory => {
                subcategory['checked'] = false;
            });
            let categoriesList = this.state.subCategoriesList;
            categoriesList[categoryName] = subCategories;
            this.setState({ subCategoriesList: categoriesList });
        }

        this.setState({ showCategory: false });
        this.setState({ showSubcategory: true });
    }

    handleCheckboxChange(categoryname, language, event, agreementSigned, exclusive) {
        event.stopPropagation()
        if (exclusive == true) {
            return false;
        }

        if (event.target.checked == true) {
            let subCategories = this.state.subCategoriesList[this.state.category];
            subCategories.forEach(subcategory => {
                if (subcategory.categoryname == categoryname) {
                    subcategory['checked'] = true;
                }
            })
            let categoriesList = this.state.subCategoriesList;
            categoriesList[this.state.category] = subCategories;
            this.setState({ subCategoriesList: categoriesList });
            this.setState({ selectedList: this.state.selectedList.concat({ "Id": null, "SubscriptionId": null, "CategoryName": categoryname, "Language": language }) });
        }
        else {
            let subCategories = this.state.subCategoriesList[this.state.category];
            subCategories.forEach(subcategory => {
                if (subcategory.categoryname == categoryname) {
                    subcategory['checked'] = false;
                }
            })
            let categoriesList = this.state.subCategoriesList;
            categoriesList[this.state.category] = subCategories;
            this.setState({ subCategoriesList: categoriesList });
            let selectedListSet = this.state.selectedList;
            selectedListSet = selectedListSet.filter(function (obj) {
                return obj.CategoryName !== categoryname;
            });
            this.setState({ selectedList: selectedListSet });
        }

    }

    updateSubscriptionDetails() {
        let subscriptionObject = {
            "SubscriptionId": null,
            "Category": "",
            "Name": "",
            "Company": "",
            "Advisor": "",
            "Agreement": true,
            "Confirm": true,
            "Email": "",
            "SubCategory": []
        };

        subscriptionObject.Category = this.state.category;
        subscriptionObject.SubCategory = this.state.selectedList;
        this.setState({ showCategory: true });
        this.setState({ showSubcategory: false });
        if (this.state.subscriptionDetail.length <= 0) {
            this.setState({ subscriptionDetail: this.state.subscriptionDetail.concat(subscriptionObject) })
        }
        else {
            let selectedSubscriptionDetail = this.state.subscriptionDetail;


            selectedSubscriptionDetail = selectedSubscriptionDetail.filter(function (obj) {
                return obj.Category !== subscriptionObject.Category;
            });
            selectedSubscriptionDetail.concat(subscriptionObject);
            this.setState({ subscriptionDetail: selectedSubscriptionDetail })
        }

        window.scrollTo(0, 0);
    }

    showSubscriptionForm() {
        if (this.subCategoriesSelected('Macroeconomics') || this.subCategoriesSelected('Fixed Income') ||
            this.subCategoriesSelected('Foreign Exchange') || this.subCategoriesSelected('Commodities')) {
            this.setState({ showCategory: false });
            this.setState({ showSubcategory: false });
            this.setState({ showForm: true });
            this.setState({ showSucess: false });
        }
    }

    generateHeading(subcategory) {
        return <div className="container-fluid" {...css(styles.noPadding)}>
            <div className="row">
                <div className="col-md-8 col-lg-8 col-sm-8 col-8" {...css(styles.subCategory)}>
                    {subcategory.categoryname}
                </div>
                <div className="col-md-3 col-lg-3 col-sm-3 col-3" {...css(styles.subCategoryCountry)}>
                    {subcategory.language}
                </div>
                <div className="col-md-1 col-lg-1 col-sm-1 col-1"  {...css(styles.pading0)}>
                    <img className="expandIcon" src={expandIcon} alt={'expand Icon'} />
                    <img className="collapseIcon" src={collapseIcon} alt={translate.strings.TR56} />
                </div>
            </div>
        </div>;
    }

    generateSubscription(elements) {
        return elements.map((value, i) => {
            return (value == "") ? <br /> : <p key={i}>{value}</p>;
        });
    }

    generatesubcategories(subcategories) {
        let subCategories = subcategories[this.state.category];
        let agreementSigned = localStorage.getItem('agreementAccepted') == 'true' || false;

        return subCategories.map((subcategory, key) => {
            return (
                <div className="container-fluid" key={key}>
                    <div className="row" {...css(styles.headingRow)}>
                        <div className="col-md-1 col-lg-1 col-sm-1 col-1">
                            <input className={(subcategory.exclusive == true) ? 'disable-checkbox' : ''} type="checkbox" name={subcategory.categoryname} value={subcategory.categoryname} checked={subcategory.checked} readOnly={agreementSigned == false && subcategory.exclusive == true}
                                onChange={(e) => this.handleCheckboxChange(subcategory.categoryname, subcategory.language, e, agreementSigned, subcategory.exclusive)} key={subcategory.categoryname} ref={subcategory.categoryname} aria-label={subcategory.categoryname} />
                        </div>
                        <div className="col-md-11 col-lg-11 col-sm-11 col-11" {...css(styles.subCategory)}>
                            <Collapsible key={key} trigger={this.generateHeading(subcategory)} transitionTime="200">
                                <p {...css(styles.categoryDetails)}>
                                    {this.generateSubscription(subcategory.description)}
                                    {(subcategory.exclusive == true) ? <span {...css(styles.contactMessage)}><br />{subcategory.disclaimer}</span> : null}
                                </p>
                            </Collapsible>
                        </div>
                    </div>
                </div>
            )
        })
    }

    handleNameChange(event) {
        this.setState({ name: event.target.value });
        if (event.target.value !== null || event.target.value != '') {
            this.setState({ validName: true });
        }
        else {
            this.setState({ validName: false });
        }
    }

    handleEmailChange(event) {
        this.setState({ email: event.target.value });
        if (/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(event.target.value)) {
            this.setState({ validEmail: true });
        }
        else {
            this.setState({ validEmail: false });
        }
    }

    handleCoompanyFieldChange(event) {
        this.setState({ company: event.target.value });
    }

    handleManagerFieldChange(event) {
        this.setState({ manager: event.target.value });
    }

    handleSubscriptionCheckboxes(type, event) {
        if (event.target.checked == true) {
            if (type == 'agreement') {
                this.setState({ acceptedAgreement: true })
            }
            else if (type == 'professional') {
                this.setState({ professional: true })
            }
            else if (type == 'notUSCitizen') {

            }
        }
        else {
            if (type == 'agreement') {
                this.setState({ acceptedAgreement: false })
            }
            else if (type == 'professional') {
                this.setState({ professional: false })
            }
            else if (type == 'notUSCitizen') {

            }
        }

    }


    submitSubscriptionForm() {
        this.setState({ subscribeClicked: true })
        /*if(this.state.acceptedAgreement == false || this.state.professional == false){
                this.setState({agreementConditionErrors: true})
        }
        else{
                this.setState({agreementConditionErrors: false})
        }*/

        if ((this.state.validEmail == true && this.state.validName == true)) {
            let requestbody = [];
            let categories = this.state.subCategoriesList;


            Object.keys(categories).forEach((category, index) => {
                let subscriptionObject = {
                    "SubscriptionId": null,
                    "Category": "",
                    "Name": "",
                    "Company": "",
                    "Advisor": "",
                    "Agreement": true,
                    "Confirm": true,
                    "Email": "",
                    "SubCategory": []
                };
                if (this.state.subCategoriesList[category].length > 0) {
                    let subcategoriesList = this.state.subCategoriesList[category];
                    subcategoriesList = subcategoriesList.filter(function (obj) {
                        return obj.checked == true;
                    });
                    if (subcategoriesList.length > 0) {
                        subscriptionObject.Category = category;
                        subscriptionObject.Name = this.state.name;
                        subscriptionObject.Company = this.state.company;
                        subscriptionObject.Advisor = this.state.manager;
                        subscriptionObject.Email = this.state.email;

                        let subcategoryList = [];

                        subcategoriesList.forEach(subcategory => {
                            subcategoryList.push({ "Id": null, "SubscriptionId": null, "CategoryName": subcategory.categoryname, "Language": subcategory.language });
                        });
                        subscriptionObject.SubCategory = subcategoryList;
                        requestbody.push(subscriptionObject)
                    }
                }
            });

            axios({
                url: window.env.subscriptionUrl,
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: { "SubscribtionProfiles": requestbody }
            })
                .then(res => {
                    this.props.history.push('/subscriptionconfirm');
                });
        }
    }

    subCategoriesSelected(category) {
        if (this.state.subCategoriesList[category].length <= 0) {
            return false;
        }
        else {
            let subCategories = this.state.subCategoriesList[category];
            subCategories = subCategories.filter(function (obj) {
                return obj.checked == true;
            })
            if (subCategories.length > 0) {
                return true;
            }
            else {
                return false;
            }
        }
    }

    showContactMessage() {
        this.setState({ showCategory: false });
        this.setState({ showContactMessage: true });
    }

    showCategoryView() {
        this.setState({ showCategory: true });
        this.setState({ showContactMessage: false });
    }

    getCatalogtranslations(text) {
        let edition = localStorage.getItem('language') || 'EN';
        if (edition == 'EN' || edition == 'ALL') {
            return text;
        }
        else {
            if (catalogTranslations[text]) {
                let translationElement = catalogTranslations[text];
                let translatedText = translationElement[edition];
                return translatedText;
            }
            else {
                return text;
            }
        }
    }

    render() {
        return (
            <div>
                <HeaderTabletSection />
                <HeaderSection showSearch={this.showSearchComponent} history={this.props.history} />
                <NavBar items={navigation.navInfo} />
                {this.state.showSearch ? <Search homepage={false} /> : null}
                {this.state.showCategory ? <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-8 col-lg-6 col-sm-8 col-10" {...css(styles.subscriptionContent)} role="region" aria-label={translate.strings.TR53}>
                            <h2 {...css(styles.categoryH2)}>{translate.strings.TR53}</h2>
                            <p {...css(styles.categorySubTitle)}>{translate.strings.TR86}</p>
                            <div {...css(styles.categoryContainer)}>
                                <div {...css(styles.categoryButtons)} onClick={() => this.populateSubcategories('Macroeconomics')}>{translate.strings.TR54}
                                    {this.subCategoriesSelected('Macroeconomics') ? <span {...css(styles.greenTick)}><img src={greenTick} alt={translate.strings.TR54} /></span> : null}
                                </div>
                                <div {...css(styles.categoryButtons)} onClick={() => this.populateSubcategories('Fixed Income')}>{translate.strings.TR55}
                                    {this.subCategoriesSelected('Fixed Income') ? <span {...css(styles.greenTick)}><img src={greenTick} alt={translate.strings.TR55} /></span> : null}
                                </div>
                                <div {...css(styles.categoryButtons)} onClick={() => this.populateSubcategories('Foreign Exchange')}>{translate.strings.TR56}
                                    {this.subCategoriesSelected('Foreign Exchange') ? <span {...css(styles.greenTick)}><img src={greenTick} alt={translate.strings.TR56} /></span> : null}
                                </div>
                                {/*<div {...css(styles.categoryButtons)} onClick = {() => this.populateSubcategories('Commodities')}>{translate.strings.TR57}
                                {this.subCategoriesSelected('Commodities')? <span {...css(styles.greenTick)}><img src={greenTick} /></span>: null}
                                </div>  */}
                                {/* <div {...css(styles.categoryButtons)} onClick={() => this.showContactMessage()}>{translate.strings.TR192}</div> */}
                                {/*<p {...css(styles.paragraph)}>
                                {translate.strings.TR38} <a href="#" target="_blank" {...css(styles.anchor)}> {translate.strings.TR58} </a>
                                    
                                </p>                      
                                <p {...css(styles.paragraph)}>
                                {translate.strings.TR60}
                                </p>*/}
                                <div role='button' {...css(styles.Button)} className={this.subCategoriesSelected('Macroeconomics') || this.subCategoriesSelected('Fixed Income') ||
                                    this.subCategoriesSelected('Foreign Exchange') || this.subCategoriesSelected('Commodities') ? '' : 'disabled-button'}
                                    onClick={() => this.showSubscriptionForm()}>{translate.strings.TR39}</div>
                            </div>
                        </div>
                    </div>
                </div> : null}

                {this.state.showSubcategory ? <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-9 col-lg-6 col-sm-9 col-12" {...css(styles.subscriptionContent)} role="region" aria-label={translate.strings.TR37}>
                            <h2 {...css(styles.H2)}>{this.state.translatedCategory}</h2>
                            <p {...css(styles.subTitle)}>{translate.strings.TR37}</p>
                            <div {...css(styles.collapsibleContainer)}>

                                {this.generatesubcategories(this.state.subCategoriesList)}

                                <div {...css(styles.Button)} onClick={() => this.updateSubscriptionDetails()}>OK</div>
                            </div>
                        </div>
                    </div>
                </div> : null}

                <div className="container-fluid">
                    {this.state.showForm ? <div className="row">
                        <div className="col-md-8 col-lg-5 col-sm-8 col-12" {...css(styles.subscriptionContent)} role="region" aria-label={'Content'}>
                            <h2 {...css(styles.H2)}>{translate.strings.TR42}</h2>
                            <div {...css(styles.fieldsContainer)}>
                                <span {...css(styles.inputLabel)}>{translate.strings.TR43} *</span>
                                <input type="text" {...css(styles.inputText, (this.state.validName == false && this.state.subscribeClicked == true) && styles.alertDangerInputBox)} autoComplete="off" placeholder={translate.strings.TR44} value={this.state.name} onChange={this.handleNameChange} aria-describedby="name_error" />
                            </div>
                            {(this.state.validName == false && this.state.subscribeClicked == true) ? <div className="alert alert-danger" id="name_error" {...css(styles.alertDangerCss)} >
                                <i aria-hidden="true" class="fa fa-caret-up"></i>
                                {translate.strings.TR44}
                            </div> : null}
                            <div {...css(styles.fieldsContainer)}>
                                <span {...css(styles.inputLabel)}>{translate.strings.TR45} *</span>
                                <input type="email" {...css(styles.inputText, (this.state.validEmail == false && this.state.subscribeClicked == true) && styles.alertDangerInputBox)} autoComplete="off" placeholder={translate.strings.TR46} value={this.state.email} onChange={this.handleEmailChange} aria-describedby="email_error" />
                            </div>
                            {(this.state.validEmail == false && this.state.subscribeClicked == true) ? <div className="alert alert-danger" id="email_error" {...css(styles.alertDangerCss)} >
                                <i aria-hidden="true" className="fa fa-caret-up"></i>
                                {translate.strings.TR46}
                            </div> : null}
                            {/* <div {...css(styles.fieldsContainer)}>
                                <span {...css(styles.inputLabel)}>Country *</span>
                                <select {...css(styles.inputText)} value={this.state.country} aria-label='Country' onChange={this.handleCountryChange} {...css(styles.inputText, (this.state.validCountry == false && this.state.subscribeClicked == true) && styles.alertDangerInputBox)} aria-describedby="country_error">
                                    <option key="select" value="">Select</option>
                                    {this.state.countryList.map(country => (
                                        <option key={country.name} value={country.name}>
                                            {country.name}
                                        </option>
                                    ))}
                                </select>
                            </div> */}
                            {(this.state.validCountry == false && this.state.subscribeClicked == true) ? <div className="alert alert-danger" id="country_error" {...css(styles.alertDangerCss)}>
                                <i aria-hidden="true" className="fa fa-caret-up"></i>
                                Choose a country
                            </div> : null}
                            <div {...css(styles.fieldsContainer)}>
                                <span {...css(styles.inputLabel)}>{translate.strings.TR47}</span>
                                <input type="text" {...css(styles.inputText)} autoComplete="off" placeholder={translate.strings.TR48} value={this.state.company} onChange={this.handleCoompanyFieldChange} />
                            </div>
                            {/*<div {...css(styles.fieldsContainer)}>
                                    <span {...css(styles.inputLabel)}>{translate.strings.TR49}</span>
                                    <input type="text" {...css(styles.inputText)}  autoComplete="false" placeholder={translate.strings.TR50} value={this.state.manager} onChange={this.handleManagerFieldChange} />
                                </div>
                                <p {...css(styles.paragraphForm)}>
                                    <span {...css(styles.checkboxContainer)}><input onChange={(e)=>this.handleSubscriptionCheckboxes('agreement', e)} type="checkbox"  /></span>
                                    <span {...css(styles.agreementText)}>{translate.strings.TR51}<a href="#" target="_blank" {...css(styles.anchor)}> {translate.strings.TR61} </a></span>
                                    
                                </p>                      
                                <p {...css(styles.paragraphForm)}>
                                <span {...css(styles.checkboxContainer)}><input onChange={(e)=>this.handleSubscriptionCheckboxes('professional', e)} type="checkbox"  /></span>
                                <span {...css(styles.agreementText)}>{translate.strings.TR34}</span>
                                </p>
                                {this.state.agreementConditionErrors ?<p {...css(styles.paragraphError)}>
                                        {translate.strings.TR191}
                                </p> : null}
                                */}
                            <div {...css(styles.consentBox)}>
                                <p className="text-justify">{translate.strings.TR228}</p>
                            </div>
                            <div role="button" onClick={() => this.submitSubscriptionForm()}
                                {...css(styles.Button)} >{translate.strings.TR52}</div>

                        </div>
                    </div> : null}
                </div>
                {this.state.showContactMessage ?
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-8 col-lg-6 col-sm-8 col-8" {...css(styles.subscriptionContent)}>
                                <h2 {...css(styles.categoryH2)}>{translate.strings.TR192}</h2>
                                <p {...css(styles.categorySubTitle)}>{translate.strings.TR193}</p>
                                <div onClick={() => this.showCategoryView()} {...css(styles.Button)} className="margin-top-125" >{translate.strings.TR194}</div>
                            </div>
                        </div>
                    </div> : null}
                <FooterSection />
            </div>
        )
    }
}

export default SubscriptionPage;